<template>
  <div class="input">
    <inputOptional
      :value="value"
      :label="$t('survey.question', { count: '' })"
      :callback="setValue"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      value: this.$t("survey.default")
    };
  },
  methods: {
    setValue() {}
  }
};
</script>
<style lang="sass" scoped>
.input
  pointer-events: none
  cursor: default
</style>
